/* exported header2017 */
var header2017 = {
    menus: function ($, document) {
        var openElement = null;
        var openContent = function (el) {
            if (openElement !== null) {
                closeContent(openElement, el);
            } else {
                doOpenContent(el);
            }
        };
        var doOpenContent = function (el) {
            openElement = el;
            el = $(el);
            var content = el.data('content');
            el.addClass('is-open');
            content.addClass('is-visible').delay(0).queue(function (next) {
                $(this).addClass('is-open');
                next();
            });
            if (el.data('header2017') !== 'search') {
                if ($('body').data('state') === 'keyboard') {
                    //content.on('transitionend webkitTransitionEnd', function () {
                    //$(this).off();
                    var toFocus = content.data('toFocus') || $(content.find('[data-header2017tofocus]').first());
                    if (!toFocus.is('a') && !toFocus.is('button')) {
                        toFocus.attr('tabindex', -1);
                    }
                    toFocus.focus();
                    //});
                }
            } else {
                setTimeout(function () {
                    var toFocus = content.data('toFocus') || $(content.find('[data-header2017tofocus]').first());
                    toFocus.focus();
                }, 10);

            }
        };
        var closeMenuImmediatly = function (el) {
            el = $(el);
            el.removeClass('is-open');
            el.data('content').removeClass('is-visible is-open');
            openElement = null;
            el.focus();
        };
        var closeContent = function (el, toOpen) {
            el = $(el);
            var content = el.removeClass('is-open').data('content');
            content.removeClass('is-open');
            if (toOpen) {
                content.removeClass('is-visible');
                openElement = null;
                doOpenContent(toOpen);
            } else {
                if (el.data('header2017') !== 'search') {
                    content.on('transitionend webkitTransitionEnd', function () {
                        openElement = null;
                        $(this).removeClass('is-visible').off();
                    });
                } else {
                    content.removeClass('is-visible');
                    openElement = null;
                }
            }
        };
        var initSubContent = function (el) {
            el = $(el);
            if (el.data('initialized') !== true) {
                var name = el.data('header2017');
                var content = $('[data-header2017content="' + name + '"]');
                if (content) {
                    var gardes = $('<button class="visuallyhidden"> </button>');
                    gardes.data('toFocus', el);
                    gardes.on('focus', function () {
                        var el = $(this).data('toFocus');
                        closeMenuImmediatly(el);
                    });
                    content.append(gardes.clone(true));
                    content.prepend(gardes.clone(true));
                    el.data('content', content);
                }
                el.data('initialized', true);
            }
        };

        var sectionMenu = $('[data-sectionmenu]');
        var sectionMenu_menu = $('[data-sectionmenu_menu]');
        var sectionMenu_more = $('[data-sectionmenu_more]');
        var sectionMenu_items = $('[data-sectionmenu_items]');
        var sectionMenu_lis = sectionMenu.find('li:visible');
        var updateSectionMenu = function () {
            sectionMenu_items.empty();
            sectionMenu_more.addClass('is-disabled');
            sectionMenu_lis.css('display', '');
            sectionMenu.addClass('has-no-more');
            sectionMenu.removeClass('is-mobile');

            var isBigger = function () {
                sectionMenu.removeClass('has-no-more');
                var width = sectionMenu_menu.outerWidth();
                var maxWidth = sectionMenu.innerWidth();
                sectionMenu.addClass('has-no-more');

                return width > maxWidth;
            };
            var transfertItem = function () {
                var lastItem = sectionMenu_lis.filter(':visible').last();
                sectionMenu_items.prepend(lastItem.clone());
                lastItem.css('display', 'none');
            };

            var isMobile = function() {
                if(sectionMenu.outerWidth() < 750){
                    return true;
                }
                return sectionMenu_lis.filter(':visible').length < 3 && sectionMenu_lis.length > 2;
            };

            var shouldTransfert = isBigger() || isMobile();
            var maxIteration = 30;
            var nbItems = 0;
            while (shouldTransfert) {
                transfertItem();
                nbItems++;
                sectionMenu_items.parent().data('toFocus', sectionMenu_items.find('a').first());

                shouldTransfert = isBigger() || nbItems < 2 || isMobile();
                sectionMenu.removeClass('has-no-more');

                maxIteration--;
                if (maxIteration <= 0) { // break if testing too much
                    break;
                }
            }
            if(isMobile()){
                sectionMenu.addClass('is-mobile');
                sectionMenu_more.removeClass('is-disabled');
            }

            sectionMenu.addClass('is-initiated');
        };

        var jsHeader = $('.js-header2017');
        jsHeader.on('click', '[data-header2017]', function (e) {
            e.preventDefault();

            initSubContent(this);

            var self = this;
            if (openElement === self) {
                closeContent(self);
            } else {
                openContent(self);
            }
        });
        jsHeader.on('click', '[data-header2017close]', function (e) {
            e.preventDefault();
            var el = $(this);
            if (el.data('initialize') !== true) {
                var name = el.data('header2017close');
                var content = $('[data-header2017content="' + name + '"]');
                var toFocus = $('[data-header2017="' + name + '"]');
                el.data('toFocus', toFocus);
                if (content) {
                    el.data('content', content);
                }
                el.data('initialize', true);
            }
            var elToFocus = el.data('toFocus');
            closeContent(elToFocus);
            elToFocus.focus();
        });


        $(document).on('keydown', function (e) {
            if (e.keyCode === 27 && openElement !== null) {
                closeContent(openElement);
            }
        });

        var classesToWatch = '.js-header2017, .ui-autocomplete';
        $(document).on('mouseenter', classesToWatch, function () {
            $('body').off('mousedown.closeMenu');
        });

        $(document).on('mouseleave', classesToWatch, function () {
            if (openElement) {
                $('body').on('mousedown.closeMenu', function () {
                    closeContent(openElement);
                    $('body').off('mousedown.closeMenu');
                });
            }
        });

        $(window).on('resize', updateSectionMenu);
        updateSectionMenu();
    },
    searchBar: function ($, window) {
        var search = $('[data-header2017search]');
        var menuBtn = $('[data-header2017="menu"]');
        var positionSearch = function () {
            var right = menuBtn.position().left + menuBtn.outerWidth();
            search.css('left', right);
        };
        positionSearch();
        $(window).on('resize', positionSearch);
    },
    init: function (jQuery, window, document) {
        this.menus(jQuery, document);
        this.searchBar(jQuery, window);
    }
};

header2017.init(jQuery, window, document);